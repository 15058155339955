import React from 'react'
import { Layout } from '../component/Layout'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { CreateAlert } from '../component/page_component/CreateAlert'
import { useIntl } from 'gatsby-plugin-intl'
import { Helmet } from 'react-helmet'

const Buy: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()

  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`
  return (
    <Layout location={location} title={intl.formatMessage({ id: 'PageTitleAlert' })}>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content="Créer votre alerte - Kretz" />  
      </Helmet>
      <CreateAlert />
    </Layout>
  )
}

export default Buy
